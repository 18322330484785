import React, { useState } from 'react';
import {
  CardBody, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import TransactionFilter from './TransactionsFilter';
import AccountSection from './AccountSection';

import styles from './BankingAccountCard.module.scss';
import CollapsibleCard from '../CollapsibleCard';

const noAccount = Object.freeze({ accountNO: '' });

function Transactions({
  accounts,
  additionalTransactions,
  transactionFilter,
  onTransactionFilterChange,
}) {
  const accountTypes = ['savings', 'checking', 'chequing'];
  const filteredAccounts = accounts.sort((a, b) => (
    accountTypes.indexOf(b.type) - accountTypes.indexOf(a.type)
  ));

  const getIdentifier = (tab) => (tab.accountNO !== null ? tab.accountNO : tab.id);

  const accountsFreqMap = {};
  const updatedAccounts = filteredAccounts.map((account) => {
    const identifier = getIdentifier(account);
    const count = accountsFreqMap[identifier] || 0;
    const updatedAccount = {
      ...account,
    };
    if (account.accountNO) updatedAccount.accountNO = `${account.accountNO} ${count ? `(${count})` : ''}`;
    accountsFreqMap[identifier] = count + 1;
    return updatedAccount;
  });

  const selectedAccount = updatedAccounts.length === 0 ? noAccount : updatedAccounts[0];
  const [activeTab, setActiveTab] = useState(selectedAccount.accountNO !== null ? selectedAccount.accountNO : selectedAccount.id);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <CardBody>
      <TransactionFilter
        selectedValue={transactionFilter}
        onChange={onTransactionFilterChange}
      />
      <Nav tabs className={styles.tabBar}>
        {updatedAccounts.map((tab) => (
          <NavItem key={getIdentifier(tab)} className={styles.navItem}>
            <NavLink
              className={activeTab === getIdentifier(tab) ? styles.active : ''}
              active={activeTab === getIdentifier(tab)}
              onClick={() => toggle(getIdentifier(tab))}
            >
              {`${tab.type} ${tab.accountNO !== null ? tab.accountNO : ''}`}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {updatedAccounts.map((tab) => (
          <TabPane tabId={getIdentifier(tab)} key={getIdentifier(tab)} className={styles.bankingCards}>
            <AccountSection accounts={[tab]} transactionFilter={transactionFilter} additionalTransactions={additionalTransactions} />
          </TabPane>
        ))}
      </TabContent>
    </CardBody>
  );
}

export default function (props) {
  const {
    accounts,
    transactions,
    transactionFilter,
    onTransactionFilterChange,
  } = props;

  if (!accounts?.length) return null;

  return (
    <CollapsibleCard title="Banking Accounts" collapseKey="bankingAccounts">
      <Transactions
        accounts={accounts}
        additionalTransactions={transactions}
        transactionFilter={transactionFilter}
        onTransactionFilterChange={onTransactionFilterChange}
      />
    </CollapsibleCard>
  );
}
